<div
  *ngrxLet="vm$; let vm"
  class="cart-container position-relative d-flex flex-column h-100 w-100 flex-grow-1 justify-content-between align-items-center"
  [class.empty-cart]="vm.itemCount === 0 && !(animations$ | async)!.length"
>
  <ng-container *ngIf="vm.itemCount > 0 || (vm.itemCount === 0 && (animations$ | async)!.length); else emptyCart">
    <div class="header w-100 d-flex flex-column">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h1 class="body-large-bold-md body-normal-bold mb-0">
          {{ 'cart.header' | pluralTranslate: vm.itemCount | translate: { itemCount: vm.itemCount } }}
        </h1>
        <button ddButton class="close-button color-text300 gap-1" size="normal" type="icon" level="tertiary" (click)="closeDropdown()">
          <svg-icon key="close" [fontSize]="(breakpointService.upLg$ | async) ? '32px' : ' 24px'"></svg-icon>
        </button>
      </div>
      <hr class="color-text200 mt-2 mb-2" />
    </div>

    <div class="cart-inner-container d-flex flex-column flex-grow-1 justify-content-between w-100 pb-3 px-2 pb-md-4 px-md-6">
      <div class="d-flex flex-column scroll w-100">
        <div
          *ngIf="vm.itemCount > 1"
          class="tooltip d-flex rounded-3 background-bg400 align-self-stretch align-items-center px-4 py-2 gap-1-5 mb-2"
        >
          <svg-icon class="info-icon flex-shrink-0" key="sign-error" fontSize="24px"></svg-icon>
          <div class="d-flex flex-column">
            <span class="body-tiny">{{ 'cart.test-results-will-appear' | translate }}</span>
          </div>
        </div>
        <div class="d-flex flex-column scroll w-100">
          <app-scroller
            class="d-flex w-100 h-100 position-relative"
            contentClass="d-flex flex-column full-width full-height h-100 flex-grow-1"
          >
            <div class="d-flex cart-content flex-column align-self-stretch">
              <app-cart-product-card
                *ngFor="let item of vm.cartItems; trackBy: trackById"
                class="mb-1"
                [@deleteAnimation]="toBeAnimated(item.variantId + item.pickedSubscription?.name)"
                (delete)="onDeleteAnimationStart(item.variantId + item.pickedSubscription?.name)"
                (@deleteAnimation.done)="onDeleteAnimationDone(item.variantId + item.pickedSubscription?.name)"
                [cartItem]="item"
                [isLoading]="!!(isLoading$ | async)"
                (changeQuantity)="updateQuantity($event)"
                (productUrlClick)="closeDropdown()"
              ></app-cart-product-card>
            </div>
          </app-scroller>
        </div>
      </div>

      <div class="d-flex flex-column w-100">
        <hr class="color-text200 mt-1 mb-3" />
        <div class="d-flex flex-column w-100">
          <div class="d-flex align-items-end justify-content-end mb-1 gap-0-5">
            <span>{{ 'cart.delivery' | translate }}</span
            ><span class="font-weight-bold">{{ 'cart.free' | translate }}</span>
          </div>
          <div class="d-flex flex-column-reverse flex-sm-row align-self-stretch align-items-lg-center justify-content-end mb-2">
            <div class="d-flex align-items-end justify-content-end mb-2 mb-md-0">
              <span class="me-1">{{ 'cart.total' | translate }}:</span>
              <strong class="body-large-bold header-h6-md cart-total-price">{{ vm.total | currency: 'EUR' : 'symbol' }}</strong>
            </div>
          </div>
          <div class="d-flex rounded-3 background-bg400 align-self-stretch align-items-center p-3 gap-3">
            <svg-icon key="van" fontSize="40px" class="flex-shrink-0"></svg-icon>
            <div class="d-flex flex-column">
              <span class="font-weight-bold">{{ 'cart.free-shipping' | translate }}</span>
              <span class="body-small body-normal-md whitespace-break-spaces">{{ 'cart.order-before-4pm' | translate }}</span>
            </div>
          </div>
          <div class="buttons w-100 mt-3 gap-1-5">
            <button ddButton class="cart-button" level="secondary" size="large" (click)="closeDropdown()">
              {{ 'cart.continue-shopping' | translate }}
            </button>
            <a
              ddButton
              class="cart-button"
              size="large"
              rightIcon="arrow-right"
              (click)="pushBeginCheckoutEvent(vm)"
              [routerLink]="[RouteSegment.Root, RouteSegment.Checkout] | localizeUrl"
            >
              {{ 'cart.checkout' | translate }}
            </a>
          </div>
          <app-payment-icons class="mt-3"></app-payment-icons>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #emptyCart>
  <div class="d-flex flex-column empty-cart justify-content-center align-items-center w-100 flex-grow-1">
    <button ddButton class="close-button color-text300 gap-1" size="normal" type="icon" level="tertiary" (click)="closeDropdown()">
      <svg-icon key="close" [fontSize]="(breakpointService.upLg$ | async) ? '32px' : ' 24px'"></svg-icon>
    </button>
    <app-cart-empty-image></app-cart-empty-image>
    <p class="header-h6 mt-5">{{ 'cart.your-cart-is-empty' | translate }}</p>
    <a ddButton size="large" [routerLink]="[RouteSegment.Root, RouteSegment.AvailableTests] | localizeUrl" (click)="closeDropdown()">
      {{ 'cart.browse-tests' | translate }}
    </a>
  </div>
</ng-template>
