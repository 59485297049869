<div
  *ngrxLet="breakpointService.upMd$ as upMd"
  [ngClass]="{
    'py-4': product.hasSubscription && !upMd,
    'py-2': !product.hasSubscription || upMd
  }"
  class="container d-flex flex-column flex-md-row justify-content-between align-items-center gap-1-5"
>
  <div *ngIf="upMd" class="d-flex gap-2 align-items-md-center">
    <div class="d-none d-md-flex flex-shrink-0">
      <img class="product-icon" [src]="product.secondaryTestkitIcon.url" [alt]="product.secondaryTestkitIcon.alt" />
    </div>
    <div *ngIf="upMd" class="title body-small header-h6-md mb-0">{{ product.title }}</div>
  </div>
  <hr *ngIf="upMd" class="color-text200 d-md-none w-100 m-0" />
  <div class="d-flex align-items-center justify-content-between gap-1-5 flex-wrap flex-md-nowrap w-100 w-md-auto">
    <div class="d-flex flex-md-row flex-column gap-1 align-items-md-center">
      <div class="d-flex gap-4 me-md-4">
        @if (selectedSubscription?.discountInPercentage) {
          <div class="discount-container background-primary500 px-1-5 rounded-1 body-tiny d-flex align-items-center py-0-5">
            <span class="color-text100">{{ 'product.off-sale' | translate: { percent: selectedSubscription?.discountInPercentage } }}</span>
          </div>
        }
        <span class="font-weight-bold">{{ selectedSubscription?.name ?? 'One Time Purchase' }}</span>
      </div>
      <div
        *ngIf="product.sale && !selectedSubscription"
        class="discount-container background-primary500 px-1-5 rounded-1 body-tiny d-flex align-items-center py-0-5 me-md-4"
      >
        <span class="color-text100">{{ 'product.off-sale' | translate: { percent: product.sale } }}</span>
      </div>
      <div class="d-flex flex-row flex-md-column gap-md-0 gap-1 align-items-md-end align-items-center me-md-8">
        @if (itemIsOnSale || subscriptionWithDiscountSelected) {
          <div class="discount-price body-tiny text-decoration-line-through">
            @if (itemIsOnSale) {
              {{ product.originalPrice | currency: 'EUR' : 'symbol' }}
            } @else {
              {{ product.price | currency: 'EUR' : 'symbol' }}
            }
          </div>
        }

        <strong
          [class.discount]="itemIsOnSale || subscriptionWithDiscountSelected"
          class="font-weight-bold body-normal body-large-md original-price"
        >
          @if (itemIsOnSale) {
            {{ product.price | currency: 'EUR' : 'symbol' }}
          } @else {
            {{ selectedSubscription?.discountedPrice | currency: 'EUR' : 'symbol' }}
          }
        </strong>
      </div>
    </div>

    <ng-container *ngIf="product.published; else notAvailable">
      <app-add-to-cart
        class="add-to-cart"
        [product]="product"
        [selectedSubscription]="selectedSubscription"
        [inverseButton]="upMd"
      ></app-add-to-cart>
    </ng-container>
    <ng-template #notAvailable>
      <button ddButton class="button d-flex position-relative" leftIcon="shopping-cart" size="normal" disabled>
        {{ 'product.out-of-stock' | translate }}
      </button>
    </ng-template>
    <app-no-more-in-stock *ngIf="noMoreItems$ | async" class="order-md-first w-100 d-md-none d-lg-block"></app-no-more-in-stock>
  </div>
</div>
