import { Injectable } from '@angular/core';
import { setSelectedSubscription } from '@shop/store/products.actions';
import { selectProduct, selectSelectedSubscription } from '@shop/store/products.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { getRouterSelectors } from '@ngrx/router-store';
import { AvailableSubscription } from '@shared/interfaces/product.interface';

const DEFAULT_PRODUCTS_LIMIT = 10;

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  public readonly product$ = this.store.select(selectProduct);
  public readonly selectedSubscription$ = this.store.select(selectSelectedSubscription);
  public readonly scrolledPastAddToCartButton$ = new BehaviorSubject<boolean>(false);

  private readonly routerSelectors = {
    ...getRouterSelectors()
  };

  constructor(private readonly store: Store) {}

  public setSelectedSubscription(subscription: AvailableSubscription | null): void {
    this.store.dispatch(setSelectedSubscription({ subscription }));
  }

  public observeAddToCartButton(isAboveViewport: boolean): void {
    this.scrolledPastAddToCartButton$.next(!isAboveViewport);
  }
}
