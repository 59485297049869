import { createAction, props } from '@ngrx/store';
import { ProductItem } from '@shared/interfaces/product.interface';
import { ApiError } from 'src/domain/api-error';
import { UpdateCartItemRequest } from 'src/domain/cart/update-cart-item-request';
import { Cart } from '@dd/shop-client-sdk';

const feature = '[Cart]';

export const getCart = createAction(`${feature} get cart`);
export const getCartSuccess = createAction(`${feature} get cart success`, props<{ cart: Cart }>());
export const getCartError = createAction(`${feature} get cart error`, props<{ error: ApiError }>());

export const checkout = createAction(`${feature} checkout`);
export const checkoutSuccess = createAction(`${feature} checkout success`);
export const checkoutError = createAction(`${feature} checkout error`, props<{ error: ApiError }>());

export const createCart = createAction(`${feature} create cart`);
export const createCartSuccess = createAction(`${feature} create cart success`, props<{ cart: Cart }>());
export const createCartError = createAction(`${feature} create cart error`, props<{ error: ApiError }>());

export const addToCart = createAction(`${feature} add items to cart`, props<{ product: ProductItem; subscriptionId?: string }>());
export const retryAddToCart = createAction(`${feature} retry adding items to cart`, props<{ product: ProductItem }>());
export const addToCartSuccess = createAction(`${feature} add items to cart success`, props<{ cart: Cart }>());
export const addToCartError = createAction(`${feature} add items to cart error`, props<{ error: ApiError }>());

export const updateItemQuantity = createAction(
  `${feature} update the quantity of an item in the cart`,
  props<{ updateCartItemRequest: UpdateCartItemRequest }>()
);
export const updateItemQuantitySuccess = createAction(
  `${feature} the quantity of an item in the cart successfully updated`,
  props<{ cart: Cart }>()
);
export const updateItemQuantityError = createAction(
  `${feature} the quantity of an item in the cart could not be updated`,
  props<{ error: ApiError }>()
);

export const cartOperationSuccess = createAction(`${feature} operation success`, props<{ cart: Cart }>());
export const cartOperationError = createAction(`${feature} operation error`, props<{ error: ApiError }>());

export const resetCart = createAction(`${feature} discard old cart`);
