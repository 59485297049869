import { createReducer, on } from '@ngrx/store';
import { LoadableStateReducerHelper } from '@shared/helpers/loadable-state-reducer.helper';
import { Loadable } from '@shared/interfaces/loadable.interface';
import { AvailableSubscription, ProductItem } from '@shared/interfaces/product.interface';
import { ApiError } from 'src/domain/api-error';
import { ProductItemBatch } from 'src/domain/product/product-batch';
import { TestProcess } from '@core/services/dato/interfaces/test-process.interface';
import { DatoProductsPage } from '@core/services/dato/interfaces/products-page.interface';
import { DatoApiError } from 'src/domain/dato-api-error';
import { KiyohPageReviewResponse } from '@shared/services/review-provider/kiyoh.interface';
import { DatoProductFilter } from '@core/services/dato/interfaces/product-filter.interface';
import {
  clearProduct,
  getCrossSellingProductsSuccess,
  getProduct,
  getProductFilters,
  getProductFiltersError,
  getProductFiltersSuccess,
  getProducts,
  getProductsPage,
  getProductsPageError,
  getProductsPageSuccess,
  getProductsSuccess,
  getProductSuccess,
  getTestProcessError,
  getTestProcessSuccess,
  setSelectedSubscription
} from './products.actions';

export const featureKeyProducts = 'products';

export interface FeatureStateProducts {
  products: Loadable<ProductItemBatch, ApiError>;
  product: Loadable<ProductItem, ApiError>;
  productsPage: Loadable<DatoProductsPage, DatoApiError>;
  productFilters: Loadable<DatoProductFilter, DatoApiError>;
  crossSellingProducts: Loadable<ProductItem[], ApiError>;
  testProcess: Loadable<TestProcess, ApiError>;
  storeReviews: Loadable<KiyohPageReviewResponse, ApiError>;
  selectedSubscription: AvailableSubscription | null;
}

export const initialState: FeatureStateProducts = {
  products: { isLoading: false },
  product: { isLoading: false },
  crossSellingProducts: { isLoading: false },
  productsPage: { isLoading: false },
  productFilters: { isLoading: false },
  testProcess: { isLoading: false },
  storeReviews: { isLoading: false },
  selectedSubscription: null
};

export const reducerProducts = createReducer(
  initialState,
  on(getProducts, (state) => ({
    ...state,
    products: LoadableStateReducerHelper.startLoad(state.products)
  })),
  on(getProductsSuccess, (state, { products }) => ({
    ...state,
    products: LoadableStateReducerHelper.loadSuccess(products)
  })),
  on(getProduct, (state) => ({
    ...state,
    product: LoadableStateReducerHelper.startLoad(state.product)
  })),
  on(getProductSuccess, (state, { product }) => ({
    ...state,
    product: LoadableStateReducerHelper.loadSuccess(product)
  })),
  on(getCrossSellingProductsSuccess, (state, { products }) => ({
    ...state,
    crossSellingProducts: LoadableStateReducerHelper.loadSuccess(products)
  })),
  on(getTestProcessSuccess, (state, { testProcess }) => ({
    ...state,
    testProcess: LoadableStateReducerHelper.loadSuccess(testProcess)
  })),
  on(getTestProcessError, (state, { error }) => ({
    ...state,
    testProcess: LoadableStateReducerHelper.loadError(state.testProcess, error)
  })),
  on(getProductsPage, (state) => ({
    ...state,
    productsPage: LoadableStateReducerHelper.startLoad(state.productsPage)
  })),
  on(getProductsPageSuccess, (state, { productsPage }) => ({
    ...state,
    productsPage: LoadableStateReducerHelper.loadSuccess(productsPage)
  })),
  on(getProductsPageError, (state, { error }) => ({
    ...state,
    productsPage: LoadableStateReducerHelper.loadError(state.productsPage, error)
  })),
  on(getProductFilters, (state) => ({
    ...state,
    productFilters: LoadableStateReducerHelper.startLoad(state.productFilters)
  })),
  on(getProductFiltersSuccess, (state, { productFilters }) => ({
    ...state,
    productFilters: LoadableStateReducerHelper.loadSuccess(productFilters)
  })),
  on(getProductFiltersError, (state, { error }) => ({
    ...state,
    productFilters: LoadableStateReducerHelper.loadError(state.productFilters, error)
  })),
  on(
    setSelectedSubscription,
    (state, { subscription }): FeatureStateProducts => ({
      ...state,
      selectedSubscription: subscription
    })
  ),
  on(
    clearProduct,
    (state): FeatureStateProducts => ({
      ...state,
      product: initialState.product,
      selectedSubscription: initialState.selectedSubscription,
      crossSellingProducts: initialState.crossSellingProducts
    })
  )
);
