import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductItem } from '@shared/interfaces/product.interface';
import { selectStoreReviews } from '@shared/modules/reviews/store/reviews.selectors';
import { mapTestProcess } from '../../../domain/mappers/map-test-process';
import { AvailableTestsVM } from '../pages/available-tests/available-tests.vm';
import { featureKeyProducts, FeatureStateProducts } from './products.reducer';

const selectProductsFeature = createFeatureSelector<FeatureStateProducts>(featureKeyProducts);

export const selectProducts = createSelector(selectProductsFeature, (state) => state.products.data);

export const selectProduct = createSelector(selectProductsFeature, (state) => {
  return state.product.data;
});

export const selectTestProcess = createSelector(selectProductsFeature, (state) => {
  return state.testProcess.data;
});

export const selectCrossSellingProducts = createSelector(selectProductsFeature, (state) => {
  return state.crossSellingProducts.data;
});

export const selectProductFilters = createSelector(selectProductsFeature, (state) => {
  return state.productFilters.data;
});

export const selectSelectedSubscription = createSelector(selectProductsFeature, (state) => {
  return state.selectedSubscription;
});

// eslint-disable-next-line sonarjs/cognitive-complexity
export const selectProductsPage = createSelector(selectProductsFeature, (state) => {
  return state.products.data
    ? ({
        title: state.productsPage.data ? state.productsPage.data.title : undefined,
        subtitle: state.productsPage.data ? state.productsPage.data.subtitle : undefined,
        products: state.products.data.entries,
        promoIcon: state.productsPage.data?.promoIcon ? state.productsPage.data.promoIcon[0] : undefined,
        testProcess: state.testProcess.data ? mapTestProcess(state.testProcess.data) : undefined,
        showReviewStars: state.productsPage.data ? state.productsPage.data.showReviewStars : false,
        backgroundColor: state.productsPage.data ? state.productsPage.data.backgroundColor : undefined,
        promoBarText: state.productsPage.data ? state.productsPage.data.promoBarText : undefined,
        enablePromoBar: state.productsPage.data ? state.productsPage.data.enablePromoBar : false,
        meta: state.productsPage.data ? state.productsPage.data.meta : undefined
      } as AvailableTestsVM)
    : undefined;
});

export const selectProductPage = createSelector(
  selectTestProcess,
  selectProduct,
  selectCrossSellingProducts,
  selectStoreReviews,
  selectSelectedSubscription,
  (testProcess, product, crossSellingProducts, storeReviews, selectedSubscription) => {
    return {
      testProcess: testProcess ? mapTestProcess(testProcess) : undefined,
      selectedSubscription,
      product: product
        ? ({
            ...product,
            reviews: storeReviews
          } as ProductItem)
        : undefined,
      crossSellingProducts
    };
  }
);
