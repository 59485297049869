<app-header></app-header>
<ng-container #promoBar></ng-container>
<main class="container d-flex flex-column flex-grow-1 pt-header">
  <div appToastMessageAnchor class="toast-anchor"></div>
  <router-outlet></router-outlet>
</main>
<ng-container *ngrxLet="product$ as product">
  <app-product-item-sticky
    *ngIf="product && (scrolledPastAddToCartButton$ | async)"
    @slideUpAnimation
    [product]="product"
    [selectedSubscription]="selectedSubscription() ?? null"
  ></app-product-item-sticky>
</ng-container>
<app-footer waIntersectionObserver (waIntersectionObservee)="onFooterIntersection($event)"></app-footer>
